import React, { Component } from 'react'
import styled from 'styled-components'
import { Box, Title3, Large, colors } from 'js-components'
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper'
import { Helmet } from 'react-helmet'
import { VideoFrame } from '../../components/VideoFrame'
import { CallToAction, GetStarted } from '../../components/CallToAction'
import { PageTitle, PageTitle2 } from '../../components/PageTitle'
import Default from '../../components/Layouts/Default';

import DeviceLineupOutline from '../images/deviceLineupOutline.svg'
import IntegrationsGrid from '../images/integrationsGrid.svg'
import AdminAlert from '../images/adminAlert.svg'
import LiveChat from '../images/liveChat.svg'
import VoipThatWorks from '../animations/voipThatWorks.json'
import t1Planet from '../images/t1Planet.png';
import ApiPuzzle from '../images/apiPuzzle.svg';



var lottie

export default class ForItLeaders extends Component {
  constructor(props) {
    super(props)
    this.animationIsAttached = false
  }

  componentDidMount() {
    lottie = require('lottie-web')
    this.attachAnimation()
  }

  attachAnimation() {
    if (this.animationContainer !== undefined && !this.animationIsAttached) {
      const animationProperties = {
        container: this.animationContainer,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: VoipThatWorks,
      }

      lottie.loadAnimation(animationProperties)
    }
  }

  render() {
    return (
      <Default>
        <Helmet>
          <title>Truly | Built With IT in Mind</title>
        </Helmet>

        <WrapperBackground
          color={colors.trulyDark}
          background='businessScene2'
        >
          <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width="1">
              <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
              for IT Leaders
              </PageTitle2>
              <PageTitle as="p" color={colors.white} mb="1.5rem" center>
                The Only Sales Productivity Platform <br/> Loved By IT Teams
              </PageTitle>
            </Box>
          </Section>
        </WrapperBackground>




    <WrapperSolid>
      <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '5rem']}>
        <Box width={[1/2, 1/2]} pb="2rem">
          <WidthImg src={t1Planet} alt="Coaching"/>
        </Box>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
          THE MOST RELIABLE VOIP INFRASTRUCTURE
          </Title3>
          <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
            Unlike other sales tools, we operate our own global voice infrastructure.  Built on AWS, we work across multiple global regions and have full multi-AZ redundancy at every layer of the stack.
            <br/><br/>
            Our softphone platform can handle the harshest networks, working flawlessly at up to 60% packet loss and 300ms of Jitter (see how we compare to the competition).
            <br/><br/>
            And when VOIP isn't an option, you can have your reps fall back to using our mobile apps on the carrier network without any impact on workflows, security or data tracking.

          </Large>
        </Box>
      </Section>
    </WrapperSolid>

    <WrapperSolid color='#292929'>
      <Section flexDirection={['column', 'row']} py={['3rem', '5rem']}>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.accentFog} bold mb="1.5rem">
           ONE CLICK INTEGRATIONS WITH YOUR ENTIRE PRODUCTIVITY STACK
          </Title3>
          <Large as="p" color={colors.white} mb="1.5rem">
           Once you have all the data in one place,  every aspect of the rep's sales execution can then be quantified into metrics using sales math and AI.  
           <br/><br/>
           These metrics then help you identify whether an issue exists (no next step exists), and if it's a skill problem (talks too much) or a will problem (no follow ups).  
          <br/><br/>
           Truly platform offers the most advanced suite of sales execution metrics in the market 'out of the box', while also making it possible to create your own without writing any code.
          </Large>
        </Box>
        <Box width={[1/2, 1/2]}>
          <CenteredImg src={IntegrationsGrid} alt="Notes"/>
        </Box>
      </Section>
    </WrapperSolid>


    <WrapperSolid>
      <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '5rem']}>
        <Box width={[1/2, 1/2]} pb="2rem">
          <WidthImg src={ApiPuzzle} alt="Coaching"/>
        </Box>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
          OPEN DATA & API ARCHITECTURE
          </Title3>
          <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
              Integrate Truly directly with your in-house products, BI tools and CRMs to create highly customized workflows.
              <br/><br/>
              Our REST/Webhook APIs give you real-time visibility into what's happening in our system and our Dynamic Routing APIs give you real-time control over call flows.
          </Large>
        </Box>
      </Section>
    </WrapperSolid>





    <WrapperSolid color='#292929'>
      <Section flexDirection={['column', 'row']} py={['3rem', '5rem']}>
        <Box width={[1, 1/2]}>
          <Title3 as="h2" color={colors.accentFog} bold mb="1.5rem">
              UNMATCHED SUPPORT
          </Title3>
          <Large as="p" color={colors.white} mb="1.5rem">
                Our support team are comprised of experiended IT support professionals with years of experience operating VOIP systems.  
                <br/><br/>
                With a 2min response time and 98% CSAT rating, your users can have their issues resolved directly with our support team so you can focus on more important tasks.
          </Large>
        </Box>
        <Box width={[1/2, 1/2]}>
          <CenteredImg src={LiveChat} alt="Notes"/>
        </Box>
      </Section>
    </WrapperSolid>


        <WrapperSolid>
          <Section flexDirection="column" my={['1rem', '2rem', '5rem']}>
            <Box width={[1, 2/3]} pb="2rem">
              <WidthImg src={DeviceLineupOutline} alt="Devices"/>
            </Box>
            <Box width={[1, 1/2]}>
              <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
              WORKS WITH YOUR EXISTING HARDWARE
              </Title3>
              <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
              Compatible with your SIP devices (desk phones, conference phones), computers (Windows, Mac, Linux, Chrome OS), and mobile devices (iOS, Android).
              </Large>
            </Box>
          </Section>
        </WrapperSolid>



    <WrapperBackground background="headsetPerson1" gradientType="purple">
      <CallToAction/>
    </WrapperBackground>

    <WrapperBackground background="sf" gradientType="black">
      <GetStarted/>
    </WrapperBackground>
    
      </Default>
    )
  }
}

const CenteredImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`

const WidthImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`
